/* Master Styles */





body {
    margin: 0;
    padding: 0;
}


.tw-spacer {
    flex: 1 1 auto;
}



a {
    cursor: pointer;
    cursor: hand;
}

button {

    background-color: #eee;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #cfd8dc;
}

button:disabled {
    background-color: #eee;
    color: #aaa;
    cursor: auto;
}

/* Navigation link styles */
nav a {
    padding: 5px 10px;
    text-decoration: none;
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
    background-color: #eee;
    border-radius: 4px;
}

nav a:visited, a:link {
    color: #607D8B;
}

nav a:hover {
    color: #039be5;
    background-color: #CFD8DC;
}

nav a.active {
    color: #039be5;
}


.tw-flex {
    display: -webkit-flex; /* Safari */
    display: flex;
}

.tw-flex-wrap {
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    flex-wrap: wrap;
}
.tw-flex-wrap-reverse {
    display: -webkit-flex; /* Safari */
    -webkit-flex-wrap: wrap; /* Safari 6.1+ */
    flex-wrap: wrap-reverse;
}

.tw-flex-flow-row-wrap {
    flex-flow: row wrap;
}

.tw-row {
    flex-direction: row;

}
.tw-row-input {
    flex-direction: row;
    align-items: baseline;
}

.tw-row-reverse {
    -webkit-flex-direction: row-reverse; /* Safari 6.1+ */
    flex-direction: row-reverse;
}

.tw-column {
    flex-direction: column;
}

.tw-input {
    padding: 2px;
    width: 100%;
}
.tw-input-10 {
    padding: 10px;
    width: 100%;
}

.tw-input-width {
    width: 300px;
}

.tw-padding-10 {
    padding: 10px;
}

.tw-margin-5 {
    margin: 5px;
}
.tw-margin-10 {
    margin: 10px;
}
.tw-margin-15 {
    margin: 15px;
}

.tw-max-width-6 {
    max-width: 600px;
}

.tw-center {
    max-width: 1000px;
    margin: auto;
    width: 100%;
}

.tw-center-600 {
    margin: auto;
    width: 600px;
}

.cssload-speeding-wheel {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: cssload-spin 575ms infinite linear;
    -o-animation: cssload-spin 575ms infinite linear;
    -ms-animation: cssload-spin 575ms infinite linear;
    -webkit-animation: cssload-spin 575ms infinite linear;
    -moz-animation: cssload-spin 575ms infinite linear;
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-spin {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-spin {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-spin {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}



.tw-height {
    height: 100%;
    height: -moz-available; /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    height: fill-available;
}

.tw-button {
    border-radius: 16px
}

.tw-row-2 {
    flex-direction: row;
    align-items: baseline;
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 900px) {
    .tw-row-2 {
        flex-direction: unset;
        flex-flow: row wrap;
    }
}

.border-panel {
    border-bottom: 1px black solid;
    box-shadow: none;
    border-radius: 0;
}

.border-panel-small {
    border-bottom: 2px #f0f0f2 solid;
    box-shadow: none;
    border-radius: 0;
}

.border-margin-bottom {
    padding-bottom: 22px;
}
.tw-desktop-g {
    display: none;
}
.tw-desktop-xl {
    display: none;
}

.tw-desktop-x {
    display: none;
}

.tw-tablet-x {
    display: none;
}

.tw-tablet {
    display: none;
}

.tw-mobile {
    display: none !important;
}

.tw-mobile-not {
    display: none;
}
.tw-button-price{
    min-width: 45px !important;
    width: 45px !important;
    height: 45px !important;
    border: none;
    font-size: 30px;
    border-radius: 0 !important;

}
#tabs .mat-tab-label {
    min-width : 0px;
    width: 167px;
    height: 100px;

}

@media (min-width: 1281px) {
    .tw-desktop-xl {
        display: inherit;
    }

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

    .tw-desktop-x {
        display: inherit;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

    .tw-desktop {
        display: inherit;
    }
}
@media (min-width: 768px)  {

    .tw-desktop-g {
        display: inherit;
    }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .tw-desktop-x {
        display: inherit;
    }

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .tw-tablet {
        display: inherit;
    }

}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
/** mobile **/
@media (max-width: 480px) {
    .tw-mobile {
        display: inherit !important;
    }
    .mat-expansion-panel-body{
        padding: 0 !important;
    }
    .tw-input-width{
        width: 100%;
    }

}
.expansion-panel-header > span:nth-of-type(2) {
    display: none;
}
/** disque top **/
@media (min-width: 481px) {
    .tw-mobile-not {
        display: inherit;
    }

}
.bt-calendar-tooltip-hour {
    display: none;
}
.main-app-footer-button-temp{
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 2;


}
.main-app-footer-button-hide{
   display: none !important;
}
.not-sellable-seat{
    fill: transparent !important;
}

.mostly-customized-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: var(--theme-primary-50); /* or add it to the track */
}
/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
    background:var(--theme-primary-500) ;
}
.mat-drawer-inner-container::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: var(--theme-primary-50); /* or add it to the track */

}
/* Add a thumb */
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
    background:var(--theme-primary-500) ;
    border-left: 1px var(--theme-primary-300) solid;
}
#map-icon path{
    fill:yellow;
}
.small-icon-tkp{
    fill: red;
}
.nextPage{
    scroll-behavior: smooth;
}
.tw-animated-zoomIn{

    animation-delay: calc(0.05s * var(--data-x-animated));
    animation-duration:750ms;
    animation-name: zoomIn;

}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 0.5;
    }
   100% {
        opacity: 1;
    }
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description{
    flex-basis: auto !important;
}

