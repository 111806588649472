@use '@angular/material' as mat;

@import '../projects/shared-lib/src/lib/scss/config';

.mat-mdc-form-field-error{
    display: flex !important;
}
.mat-mdc-form-field-error-wrapper {
   position: inherit !important;
}
//.mat-mdc-form-field {
//    display: flow !important;
//}
.tw-form-error-car{
    background: #80808047;
    color: black;
    margin: 0 2px;
    padding: 0 5px;
    font-weight: bold;
}
