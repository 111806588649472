@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600,600i');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "../scss/color";
@import "../scss/typography";


@include mat.core();


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$dynamic-app-light-theme: mat.define-light-theme(
        (
            color: (
                primary: mat.define-palette($dynamic-theme-primary),
                accent: mat.define-palette($dynamic-theme-accent),
            )
        )
);
$dynamic-app-dark-theme: mat.define-dark-theme(
        (
            color: (
                primary: mat.define-palette($dynamic-theme-primary),
                accent: mat.define-palette($dynamic-theme-accent),
            ),
        )
);




.feq-theme {
    p, div, span, input {
        font-family: 'Roboto';
    }

    @include mat.all-component-typographies($feq-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-stroked-button, .mat-mdc-flat-button {
        border-radius: 0;
    }
}

.mta-theme {

    @include mat.all-component-typographies($mta-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    p, div, span, input {
        font-family: 'Roboto';
    }

    .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-stroked-button, .mat-mdc-flat-button {
        border-radius: 0;
    }
}

.tkp-theme {


    @include mat.all-component-typographies($tkp-typography);
    @include mat.all-component-themes($dynamic-app-light-theme);

    .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-stroked-button, .mat-mdc-flat-button {
        border-radius: 0;
    }

    p, div, span, input {
        font-family: 'Open Sans';
    }


    .tw-btn-hover-transition .mdc-button__label:hover {
        color: white;
    }

    .tw-btn-hover-transition {
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .tw-btn-hover-transition::after {
        content: "";
        background: #E6322D;
        position: absolute;
        z-index: -1;
        padding: 10px 20px;
        display: block;
        left: -20%;
        right: -20%;
        top: 0;
        bottom: 0;
        transform: skewX(-45deg) scale(0, 1);

    }

    .tw-btn-hover-transition:hover::after {
        transform: skewX(-45deg) scale(3, 1);
        transition: all 1s ease-out;
    }

    .mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: 0px 1px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px 0px rgba(0, 0, 0, 0.12) !important;
    }

    .mat-mdc-unelevated-button.mat-primary {

        --mdc-filled-button-label-text-color: white !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: #ffffff;
    }

    .dark-them {
        @include mat.all-component-themes($dynamic-app-dark-theme);
    }

    .mat-mdc-progress-bar {
        --mdc-linear-progress-active-indicator-color: var(--theme-primary-100);
    }

}

.tw-primary {
    color: var(--primaryColor) !important;
    background-color: var(--primaryBackground) !important;
}

.tw-primary-color {
    color: var(--primaryBackground) !important;
}

.tw-accent {
    color: var(--accentColor) !important;
    background-color: var(--accentBackground) !important;
}

.tw-warn {
    color: var(--warnColor) !important;
    background-color: var(--warnBackground) !important;
}

.tw-app-background {
    max-width: 1200px;
    margin: auto;
    background-color: var(--appBackground) !important;
}

.tw-snack-bar {
    background: var(--theme-primary-600) !important;
    margin: 14px !important;
}

.tw-snack-bar-red {
    background: red !important;
    margin: 14px !important;
}

//.mat-expansion-panel-spacing{
//    margin:0 !important;
//
//}
.expansion-panel-header > span:nth-of-type(2) {
    display: none;
}

.header-title-temp {
    margin: 10px;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
    align-items: flex-start !important;
}

.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
    background: var(--theme-primary-500);
}

.mat-drawer-inner-container::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: var(--theme-primary-50); /* or add it to the track */

}

/* Add a thumb */
.mat-drawer-inner-container::-webkit-scrollbar-thumb {
    background: var(--theme-primary-500);
    border-left: 1px var(--theme-primary-300) solid;
}

:root {
    --primaryColor: #FFF;
    --primaryBackground: #151a4f;
    --accentColor: rgba(0, 0, 0, .87);
    --accentBackground: #008000;
    --warnColor: #FFF;
    --warnBackground: #f44336;
    --appBackground: #FFF;
    --mdc-filled-button-label-text-color: #FFF !important;
}

@media screen and (max-width: 728px) {
    .tw-app-background {
        margin: 0px 0px 20px 0px !important;
    }
}
